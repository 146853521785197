import React, {useContext, useEffect, useState} from 'react';
import dayjs from "dayjs";
import './BetMode.css'
import livePng from "../../assets/icons/live-icon.png";
import BetModeByLeagueContent from "./BetModeByLeagueContent";
import {
    fetchFavoritesBetModeMatches,
    fetchLiveScores,
    fetchLiveScoresInstant
} from "../../api";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {scoreRadarMeta} from "../../SEO/SeoMetaTags";

function BetMode(props) {
    const {favBetModeMatches, lang} = useContext(AppContext);

    const [live, setLive] = useState(false);
    const [liveMatchesCount, setLiveMatchesCount] = useState(0);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [liveScoresData, setLiveScoresData] = useState(null);
    const [scoreData, setScoreData] = useState(null);
    const [liveScoresInstantData, setLiveScoresInstantData] = useState(null);
    const [fetchFavBetModeMatches, setFetchFavBetModeMatches] = useState([]);
    const [favBetModeMatchesId, setFavBetModeMatchesId] = useState([]);

    useEffect(() => {
        fetchLiveScores(currentDate).then((data) => {
            const liveScoreData = data.data.data;
            setScoreData(liveScoreData);
            setLiveScoresInstantData(liveScoreData);
        });

        /*const interval = setInterval(() => {
            fetchLiveScoresInstant(currentDate).then((response) => {
                const instantMatches = response.data.data;
                setLiveScoresInstantData(instantMatches);
            })
        }, 10000);
        return () => clearInterval(interval);*/
    }, [currentDate]);

    /*SET SCORE DATA*/
    useEffect(() => {
        const leaguesWithLiveMatches = [];
        let totalLiveCount = 0;
        if (scoreData !== null) {
            scoreData.forEach(league => {
                const liveMatches = league.matches.filter(match => {
                    return match.status === 'Playing'
                })

                if (liveMatches.length > 0) {
                    leaguesWithLiveMatches.push({
                        ...league,
                        matches: liveMatches
                    })
                    totalLiveCount += liveMatches.length
                }
            })
            setLiveMatchesCount(totalLiveCount)

            if (live) {
                setLiveScoresData(leaguesWithLiveMatches)
            } else {
                setLiveScoresData(scoreData)
            }
        }
    },[scoreData, live]);

    /*LIVE MATCHES*/
    useEffect(() => {
        const today = new Date().toISOString().substr(0, 10);

        if (currentDate === today) {
            const interval = setInterval(() => {
                fetchLiveScoresInstant(currentDate).then((response) => {
                    const instantMatches = response.data.data;
                    setLiveScoresInstantData(instantMatches);
                })
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [currentDate]);

    const mergedInstantMatches = !liveScoresInstantData
        ? liveScoresData
        : liveScoresData?.map(league => {
            return {
                ...league,
                matches: league.matches.map(match => {
                    return {
                        ...match,
                        ...(liveScoresInstantData.find(instantMatch => match.id === instantMatch.id) || {})
                    }
                })
            }
        });

    /*FAV MATCHES CODES*/
    useEffect(() => {
        if (favBetModeMatches.length > 0) {
            const matchesId = favBetModeMatches.map(item => item.id);
            setFavBetModeMatchesId(matchesId);
            fetchFavoritesBetModeMatches(matchesId).then(data => {
                const fetchMatches = data.data.data;
                setFetchFavBetModeMatches(fetchMatches);
            });

            const updater = () => {
                fetchFavoritesBetModeMatches(matchesId).then(data => {
                    const fetchMatches = data.data.data;
                    setFetchFavBetModeMatches(fetchMatches);
                });
            }
            const interval = setInterval(updater, 10000);
            return () => clearInterval(interval);
        }
    }, [favBetModeMatches]);


    const [week, setWeek] = useState([
        {day: dayjs(currentDate).add(-2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD')},
        {day: new Date().toISOString().substr(0, 10),},
        {day: dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(2, 'days').format('YYYY-MM-DD')},
    ]);

    //Week Days Format
    const format = (day) => {
        return dayjs(day).format('dddd')
    }

    const formatddd = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    const clickDays = async (day) => {
        await setCurrentDate(day)
    }


    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'bet-mode-active-week-day'
        }
    }

    const liveMatchButton = (live) => {
        setLive(!live)
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        const yesterday = dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD')
        const tomorrow = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        if (date.day === currentDate) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <span className='bet-mode-date-day-name'>Bugün</span>
                            :
                            <span className='bet-mode-date-day-name'>Today</span>
                    }
                </div>
            )
        } else if (date.day === yesterday) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <span className='bet-mode-date-day-name'>Dün</span>
                            :
                            <span className='bet-mode-date-day-name'>Yesterday</span>
                    }
                </div>
            )
        } else if (date.day === tomorrow) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <span className='bet-mode-date-day-name'>Yarın</span>
                            :
                            <span className='bet-mode-date-day-name'>Tomorrow</span>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <span>{format2(date.day)}</span>
                    <span className='bet-mode-date-day-name bet-mode-date-margin-left'>{format(date.day)}</span>
                </div>
            )
        }
    }

    if (!mergedInstantMatches) {
        return (
            <div className=" const-width loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        )
    }

    return (
        <DocumentMeta {...scoreRadarMeta[lang]}>
            <div className={'live-scores-general-section'}>
                <div className={'live-scores-live-and-dates-container-web'}>
                    <div className="live-status-container">
                        <div className="live-container" onClick={() => liveMatchButton(live)}>
                            {
                                live ?
                                    <div className="live-match">
                                        <img className="live-icon" src={livePng} alt=""/>
                                        {
                                            lang === 'tr' ?
                                                <span className="live-text">Canlı</span>
                                                :
                                                <span className="live-text">Live</span>
                                        }
                                        <span className={'live-match-count'}>{liveMatchesCount}</span>
                                    </div>
                                    :
                                    <div className="not-live">
                                        <img className="not-live-icon" src={livePng} alt=""/>
                                        {
                                            lang === 'tr' ?
                                                <span className="not-live-text">Canlı</span>
                                                :
                                                <span className="not-live-text">Live</span>

                                        }
                                        <span className={'live-match-count'}>{liveMatchesCount}</span>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="week-days-section">
                        {
                            week.map((day) => {
                                return (
                                    /*<div key={day.day} className="week-days-container">
                                        <div onClick={() => clickDays(day.day)}
                                             className={`${'dates'} ${activeDayStyle(day)}`}>
                                            <span>{format2(day.day)}</span>
                                            <span className={'date-day-name'}>{format(day.day)}</span>
                                        </div>
                                    </div>*/
                                    <div key={day.day} className="week-days-container">
                                        <div onClick={() => clickDays(day.day)}
                                             className={`${'bet-mode-dates'} ${activeDayStyle(day)}`}>
                                            {/*<span>{format2(day.day)}</span>
                                        <span className={'date-day-name'}>{format(day.day)}</span>*/}
                                            {daysFormatFunc(day)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'live-scores-live-and-dates-container-mobile const-width-content'}>
                    <div className="live-status-container">
                        <div className="live-container" onClick={() => liveMatchButton(live)}>
                            {
                                live ?
                                    <div className="live-match">
                                        <img className="live-icon" src={livePng} alt=""/>
                                        {
                                            lang === 'tr' ?
                                                <span className="live-text">Canlı</span>
                                                :
                                                <span className="live-text">Live</span>
                                        }
                                        <span className={'live-match-count'}>{liveMatchesCount}</span>
                                    </div>
                                    :
                                    <div className="not-live">
                                        <img className="not-live-icon" src={livePng} alt=""/>
                                        {
                                            lang === 'tr' ?
                                                <span className="not-live-text">Canlı</span>
                                                :
                                                <span className="not-live-text">Live</span>

                                        }
                                        <span className={'live-match-count'}>{liveMatchesCount}</span>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="week-days-section">
                        {
                            week.map((day) => {
                                return (
                                    <div key={day.day} className="week-days-container">
                                        <div onClick={() => clickDays(day.day)}
                                             className={`${'dates'} ${activeDayStyle(day)}`}>
                                            <span className={'date-day-name'}>{formatddd(day.day)}</span>
                                            <span>{format2(day.day)}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div>
                    <BetModeByLeagueContent fetchFavBetModeMatches={fetchFavBetModeMatches}
                                            liveScoresData={mergedInstantMatches} live={live} currentDate={currentDate}/>
                </div>
            </div>
        </DocumentMeta>
    );
}

export default BetMode;
