import React, {useContext, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import fixturesIcon from '../../assets/icons/matches.png'
import fixturesSelectedIcon from '../../assets/icons/matches-selected.png'
import betMode from '../../assets/icons/betMode.png'
import betModeSelected from '../../assets/icons/betModeSelected.png'
import bestTeams from '../../assets/icons/best-teams.png'
import bestTeamsSelected from '../../assets/icons/best-teams-selected.png'
import statsIcon from '../../assets/icons/statsIcon.png'
import statsSelectedIcon from '../../assets/icons/statsSelectedIcon.png'
import trendMatchesIcon from '../../assets/icons/trendMatches.png'
import trendMatchesSelected from '../../assets/icons/trendMatchesSelected.png'
import {AppContext} from "../../context/AppContext";
import GoogleAds from "../google-ads/GoogleAds";

function TopMenus(props) {
    const {TR, EN} = useContext(AppContext);
    const currentRoutes = useLocation();

    const [menuEnWeb, setMenuEnWeb] = useState([
        {text: 'Live Scores', to: '/'},
        {text: 'Stats', to: '/stats'},
        {text: 'Best Teams', to: '/best-teams'},
        {text: 'Best Matches', to: '/trend-matches'},
        {text: 'ScoreRadar', to: '/scoreradar'},
        {text: 'Leagues', to: '/league'},
        {text: 'Tv Guide', to: '/tv'},
    ])
    const [menuTrWeb, setMenuTrWeb] = useState([
        {text: 'Canlı Skor', to: '/'},
        {text: 'İstatistik', to: '/stats'},
        {text: 'En İyi Takımlar', to: '/best-teams'},
        {text: 'En İyi Maçlar', to: '/trend-matches'},
        {text: 'SkorRadar', to: '/scoreradar'},
        {text: 'Ligler', to: '/league'},
        {text: 'Futbol Ekranı', to: '/tv'},
    ])
    const [menuEnMobile, setMenuEnMobile] = useState([
        {text: 'Matches', to: '/', src: fixturesIcon, selectedSrc: fixturesSelectedIcon, toggle: 0},
        {text: 'Stats', to: '/stats', src: statsIcon, selectedSrc: statsSelectedIcon, toggle: 1},
        {text: 'Best Teams', to: '/best-teams', src: bestTeams, selectedSrc: bestTeamsSelected, toggle: 2},
        {text: 'T. Matches', to: '/trend-matches', src: trendMatchesIcon, selectedSrc: trendMatchesSelected, toggle: 3},
        {text: 'ScoreRadar', to: '/scoreradar', src: betMode, selectedSrc: betModeSelected, toggle: 4},
    ])
    const [menuTrMobile, setMenuTrMobile] = useState([
        {text: 'Maçlar', to: '/', src: fixturesIcon, selectedSrc: fixturesSelectedIcon, toggle: 0},
        {text: 'İstatistik', to: '/stats', src: statsIcon, selectedSrc: statsSelectedIcon, toggle: 1},
        {text: 'Takımlar', to: '/best-teams', src: bestTeams, selectedSrc: bestTeamsSelected, toggle: 2},
        {text: 'Trend Maçlar', to: '/trend-matches', src: trendMatchesIcon, selectedSrc: trendMatchesSelected, toggle: 3},
        {text: 'SkorRadar', to: '/scoreradar', src: betMode, selectedSrc: betModeSelected, toggle: 4},
    ])
    const [toggle, setToggle] = useState(0);

    const clickTabs = (toggle) => {
        setToggle(toggle)
    }

    const setActiveMenuStyle = (isActive) => {
        if (isActive) {
            return {
                color: '#0c0c0c',
                borderBottom: '3px solid #333333',
                padding: '0 16px 7px 16px',
                fontWeight: '600',
                fontSize: '14px'
            }
        } else {
            return {
                color: '#848484',
                borderBottom: 'none',
                paddingBottom: '0',
                padding: '0 16px',
                fontWeight: '600',
                fontSize: '14px'
            }
        }
    }

    const setActiveMenuStyleMobile = (isActive) => {
        if (isActive) {
            return {
                minHeight: '2.8rem',
                display: 'grid',
                placeItems: 'center',
                fontSize: '12px',
                transitionDelay: '.1s',
                color: '#ff0046',
                transitionDuration: '.2s',
            }
        } else {
            return {
                color: '#757d86',
                borderBottom: 'none',
                paddingBottom: '0',
                minHeight: '2.8rem',
                placeItems: 'center',
                fontSize: '12px',
                transitionDelay: '.1s',
                transitionDuration: '.2s',
            }
        }
    }

    return (
        <div className="top-menu-container">
            <div className="fv-menu-items-container">
                {
                    TR ?
                        menuTrWeb.map((menu) => {
                            return (
                                /*<a className="top-menu-a" key={menu.to} href={menu.to}>{menu.text}</a>*/
                                <NavLink key={menu.to} style={({isActive}) => (setActiveMenuStyle(isActive))}
                                         className="fv-menu-item" to={menu.to}>{menu.text}</NavLink>
                            )
                        }) :
                        menuEnWeb.map((menu) => {
                            return (
                                /*<a className="top-menu-a" key={menu.to} href={menu.to}>{menu.text}</a>*/
                                <NavLink key={menu.to} style={({isActive}) => (setActiveMenuStyle(isActive))}
                                         className="fv-menu-item" to={menu.to}>{menu.text}</NavLink>
                            )
                        })
                }
            </div>


            {/*<GoogleAds currentPath={currentRoutes.pathname}></GoogleAds>*/}

            <div className="menu-items-container-mobile">
                {
                    TR ?
                        menuTrMobile.map((menu) => {
                            return (
                                /*<a className="top-menu-a" key={menu.to} href={menu.to}>{menu.text}</a>*/
                                <NavLink onClick={() => clickTabs(menu.toggle)} key={menu.to}
                                         style={({isActive}) => (setActiveMenuStyleMobile(isActive))}
                                         className="menu-item" to={menu.to}>
                                    {
                                        toggle === menu.toggle ?
                                            <div>
                                                <img className={'menu-icons'} src={menu.selectedSrc} alt={menu.text}/>
                                                <div className='mobile-menu-title'>{menu.text}</div>
                                            </div>
                                            :
                                            <div>
                                                <img className={'menu-icons'} src={menu.src} alt={menu.text}/>
                                                <div className='mobile-menu-title'>{menu.text}</div>
                                            </div>
                                    }
                                </NavLink>
                            )
                        }) :
                        menuEnMobile.map((menu) => {
                            return (
                                /*<a className="top-menu-a" key={menu.to} href={menu.to}>{menu.text}</a>*/
                                <NavLink onClick={() => clickTabs(menu.toggle)} key={menu.to}
                                         style={({isActive}) => (setActiveMenuStyleMobile(isActive))}
                                         className="menu-item" to={menu.to}>
                                    {
                                        toggle === menu.toggle ?
                                            <div>
                                                <img className={'menu-icons'} src={menu.selectedSrc} alt={menu.text}/>
                                                <div>{menu.text}</div>
                                            </div>
                                            :
                                            <div>
                                                <img className={'menu-icons'} src={menu.src} alt={menu.text}/>
                                                <div>{menu.text}</div>
                                            </div>
                                    }
                                </NavLink>
                            )
                        })
                }
            </div>
        </div>
    );
}

export default TopMenus;
