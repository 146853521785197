import React, {createContext, useEffect, useState} from 'react';
import {json, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import('dayjs/locale/tr')

export const AppContext = createContext();

const checkProUser = async () => {
    const userDetailResponse = localStorage.getItem('user');
    const userDetailObject = JSON.parse(userDetailResponse);
    if (userDetailObject !== null) {
        return !!userDetailObject?.premium?.premium;
    }
}

export const AppProvider = ({children}, props) => {
    const [favMatches, setFavMatches] = useState([]);
    const [favBetModeMatches, setBetModeMatches] = useState([]);
    const [favLiveScoresMatches, setFavLiveScoresMatches] = useState([]);
    const [loginError, setLoginError] = useState(null);
    const [TR, setTR] = useState(false);
    const [EN, setEn] = useState(false);
    const [lang, setLang] = useState('en');
    const [isPro, setIsPro] = useState(false);

    useEffect(() => {
        checkProUser().then(isUserPro => {
            setIsPro(isUserPro);
        });
    }, [isPro]);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if (language !== null) {
            setLang(language);
            localStorage.setItem('language', language);
        } else {
            setLang('en');
            localStorage.setItem('language', 'en');
        }

        if (language === 'tr') {
            setTR(true)
        } else {
            setEn(true);
        }
    }, [lang]);

    /*LANGUAGE*/
    const setLanguage = (lan) => {
        localStorage.setItem('language', lan);
        const language = localStorage.getItem('language');
        if (language === 'en') {
            setEn(true);
            setTR(false);
            setLang('en');
        } else {
            setTR(true);
            setEn(false);
            setLang('tr');
        }
    }
    dayjs.locale(lang);

    /*FAV MATCH*/

    /*FOR FIXTURES*/
    const addAndRemoveFavMatches = async (league, match) => {
        if (favMatches.some(favMatch => favMatch.match.id === match.id)) {
            setFavMatches(
                favMatches.filter(favMatch => favMatch.match.id !== match.id),
            );
        } else {
            const favMatch = {
                leagueDetail: league,
                match,
            };
            setFavMatches([...favMatches, favMatch]);
        }
    };
    const isFav = id => {
        return favMatches.some(favMatch => favMatch.match.id === id);
    };

    /*FOR BET MODE*/
    const addAndRemoveFavBetModeMatches = async (match) => {
        if (favBetModeMatches.some(favMatch => favMatch.id === match.id)) {
            setBetModeMatches(
                favBetModeMatches.filter(favMatch => favMatch.id !== match.id),
            );
        } else {
            console.log(22)
            setBetModeMatches([...favBetModeMatches, match]);
        }
    };
    const isFavBetMode = id => {
        return favBetModeMatches.some(favMatch => favMatch.id === id);
    };

    /*FOR LIVE SCORES*/
    const addAndRemoveFavLiveScoresMatches = async (league, match) => {
        if (favLiveScoresMatches.some(favMatch => favMatch.match.id === match.id)) {
            setFavLiveScoresMatches(
                favLiveScoresMatches.filter(favMatch => favMatch.match.id !== match.id),
            );
        } else {
            const favMatch = {
                leagueDetail: league,
                match,
            };
            setFavLiveScoresMatches([...favLiveScoresMatches, favMatch]);
        }
    };
    const isFavLiveScores = id => {
        return favLiveScoresMatches.some(favMatch => favMatch.match.id === id);
    };

    /*AUTHENTICATION*/
    const register = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const name = data.get("name");
        const password = data.get("password");
        const username = data.get("username");
        const email = data.get("email");
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-platform': 'FutbolVerileri'},
            body: JSON.stringify({
                name,
                password,
                username,
                email
            })
        };
        const response = await fetch('https://santra24.6stats.com/api/user/register', requestOptions);
        const responseObject = await response.json();
        if (responseObject.success) {
            window.location.href = '/sign-in'
        }
    };

    const login = async (event, redirect) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get("username");
        const password = data.get("password");
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-platform': 'FutbolVerileri'},
            body: JSON.stringify({
                username,
                password,
            })
        };
        const response = await fetch('https://santra24.6stats.com/api/user/login', requestOptions);
        const responseObject = await response.json();
        if (responseObject.success) {
            localStorage.setItem('user-token', responseObject.data.token);
            setLoginError(false);
            const detailPayload = {
                method: 'GET',
                headers: {'Authorization': `Bearer ${responseObject.data.token}`},
            }
            const userDetail = await fetch('https://santra24.6stats.com/api/user/me', detailPayload);
            const user = await userDetail.json();
            if (user.success === true) {
                localStorage.setItem('user', JSON.stringify(user.data));
                localStorage.setItem('username', user.data.username);
            }
            if (redirect) {
                window.location.href = '/join-premium';
            } else {
                window.location.href = '/';
            }

        } else {
            setLoginError(true)
        }
    };

    const logOut = () => {
        localStorage.removeItem('user-token');
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    return (
        <AppContext.Provider
            value={{
                addAndRemoveFavLiveScoresMatches,
                addAndRemoveFavMatches,
                addAndRemoveFavBetModeMatches,
                favLiveScoresMatches,
                favMatches,
                favBetModeMatches,
                isFavLiveScores,
                isFav,
                isFavBetMode,
                register,
                login,
                logOut,
                loginError,
                setLanguage,
                lang,
                TR,
                EN,
                isPro
            }}>
            {children}
        </AppContext.Provider>
    );
};
