import React, {useContext, useEffect, useState} from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import './BestTeams.css'
import BestTeamsContent from "./BestTeamsContent";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {bestTeamsMeta} from "../../SEO/SeoMetaTags";
import {useNavigate, useParams} from "react-router-dom";

const eventTypes = [
    {
        typeName: 'goal-stats',
        index: 0,
        tabs: [
            {
                en: {
                    name: "Goal",
                    titles: [
                        {
                            type: "Over_2_5_Goals",
                            odd: "Over 2.5",
                            index: "goal25"
                        },
                        {
                            type: "Over_1_5_Goals",
                            odd: "Over 1.5",
                            index: "goal15"
                        },
                        {
                            type: "Over_0_5_Goals",
                            odd: "Over 0.5",
                            index: "goal05"
                        },
                        {
                            type: "Over_3_5_Goals",
                            odd: "Over 3.5",
                            index: "goal35"
                        },
                        {
                            type: "Over_4_5_Goals",
                            odd: "Over 4.5",
                            index: "goal45"
                        },
                        {
                            type: "BTTS",
                            odd: "BTTS",
                            index: "btts"
                        },
                        {
                            type: "Over_0_5_Goals_1H",
                            odd: "Over 0.5 1H",
                            index: "goal051h"
                        },
                        {
                            type: "Over_1_5_Goals_1H",
                            odd: "Over 1.5 1H",
                            index: "goal151h"
                        },
                        {
                            type: "Over_0_5_Goals_2H",
                            odd: "Over 0.5 2H",
                            index: "goal052h"
                        },
                        {
                            type: "Over_1_5_Goals_2H",
                            odd: "Over 1.5 2H",
                            index: "goal152h"
                        },
                    ]
                },
                tr: {
                    name: "Gol",
                    titles: [
                        {
                            type: "Over_2_5_Goals",
                            odd: "2.5 Üst",
                            index: "goal25"
                        },
                        {
                            type: "Over_1_5_Goals",
                            odd: "1.5 Üst",
                            index: "goal15"
                        },
                        {
                            type: "Over_0_5_Goals",
                            odd: "0.5 Üst",
                            index: "goal05"
                        },
                        {
                            type: "Over_3_5_Goals",
                            odd: "3.5 Üst",
                            index: "goal35"
                        },
                        {
                            type: "Over_4_5_Goals",
                            odd: "4.5 Üst",
                            index: "goal45"
                        },
                        {
                            type: "BTTS",
                            odd: "Karşılıklı Gol",
                            index: "btts"
                        },
                        {
                            type: "Over_0_5_Goals_1H",
                            odd: "Üst 0.5 1H",
                            index: "goal051h"
                        },
                        {
                            type: "Over_1_5_Goals_1H",
                            odd: "Üst 1.5 1H",
                            index: "goal151h"
                        },
                        {
                            type: "Over_0_5_Goals_2H",
                            odd: "Üst 0.5 2H",
                            index: "goal052h"
                        },
                        {
                            type: "Over_1_5_Goals_2H",
                            odd: "Üst 1.5 2H",
                            index: "goal152h"
                        },
                    ]
                }
            },
        ]
    },
    {
        typeName: 'corner-stats',
        index: 1,
        tabs: [
            {
                en: {
                    name: "Corner",
                    titles: [
                        {
                            "type": "Over_9_5_Corners",
                            "odd": "Over 9.5",
                            "index": "over95"
                        },
                        {
                            "type": "Over_8_5_Corners",
                            "odd": "Over 8.5",
                            "index": "over85"
                        },
                        {
                            "type": "Over_7_5_Corners",
                            "odd": "Over 7.5",
                            "index": "over75"
                        },
                        {
                            "type": "Over_6_5_Corners",
                            "odd": "Over 6.5",
                            "index": "over65"
                        },
                        {
                            "type": "Over_10_5_Corners",
                            "odd": "Over 10.5",
                            "index": "over105"
                        },
                        {
                            "type": "Over_11_5_Corners",
                            "odd": "Over 11.5",
                            "index": "over115"
                        },
                    ]
                },
                tr: {
                    name: "Korner",
                    titles: [
                        {
                            "type": "Over_9_5_Corners",
                            "odd": "9.5 Üst",
                            "index": "over95"
                        },
                        {
                            "type": "Over_8_5_Corners",
                            "odd": "8.5 Üst",
                            "index": "over85"
                        },
                        {
                            "type": "Over_7_5_Corners",
                            "odd": "7.5 Üst",
                            "index": "over75"
                        },
                        {
                            "type": "Over_6_5_Corners",
                            "odd": "6.5 Üst",
                            "index": "over65"
                        },
                        {
                            "type": "Over_10_5_Corners",
                            "odd": "10.5 Üst",
                            "index": "over105"
                        },
                        {
                            "type": "Over_11_5_Corners",
                            "odd": "11.5 Üst",
                            "index": "over115"
                        },
                    ]
                }
            },
        ]
    },
    {
        typeName: 'card-stats',
        index: 2,
        tabs: [
            {
                en: {
                    name: "Card",
                    titles: [
                        {
                            "type": "Over_0_5_Cards",
                            "odd": "Over 0.5",
                            "index": "over05"
                        },
                        {
                            "type": "Over_1_5_Cards",
                            "odd": "Over 1.5",
                            "index": "over15"
                        },
                        {
                            "type": "Over_2_5_Cards",
                            "odd": "Over 2.5",
                            "index": "over25"
                        },
                        {
                            "type": "Over_3_5_Cards",
                            "odd": "Over 3.5",
                            "index": "over35"
                        },
                        {
                            "type": "Over_4_5_Cards",
                            "odd": "Over 4.5",
                            "index": "over45"
                        },
                        {
                            "type": "Over_5_5_Cards",
                            "odd": "Over 5.5",
                            "index": "over55"
                        },
                        {
                            "type": "Over_6_5_Cards",
                            "odd": "Over 6.5",
                            "index": "over65"
                        },
                    ]
                },
                tr: {
                    name: "Kart",
                    titles: [
                        {
                            "type": "Over_0_5_Cards",
                            "odd": "0.5 Üst",
                            "index": "over05"
                        },
                        {
                            "type": "Over_1_5_Cards",
                            "odd": "1.5 Üst",
                            "index": "over15"
                        },
                        {
                            "type": "Over_2_5_Cards",
                            "odd": "2.5 Üst",
                            "index": "over25"
                        },
                        {
                            "type": "Over_3_5_Cards",
                            "odd": "3.5 Üst",
                            "index": "over35"
                        },
                        {
                            "type": "Over_4_5_Cards",
                            "odd": "4.5 Üst",
                            "index": "over45"
                        },
                        {
                            "type": "Over_5_5_Cards",
                            "odd": "5.5 Üst",
                            "index": "over55"
                        },
                        {
                            "type": "Over_6_5_Cards",
                            "odd": "6.5 Üst",
                            "index": "over65"
                        },
                    ]
                }
            },
        ]
    }
]

function BestTeams(props) {
    const {lang} = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();

    const [selectedEventType, setSelectedEventType] = useState('');
    const [typeName, setTypeName] = useState('');
    const [statsName, setStatsName] = useState('');
    const [selectedType, setSelectedType] = useState('');

    useEffect(() => {
        if (params.stats && params.type) {
            function capitalizeFirstLetter(str) {
                return str.replace(/\b\w+\b/g, function (word) {
                    const ff = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                    if (ff.find(item => word[0] === item)) {
                        return word.substring(0, 1) + word.charAt(1).toUpperCase() + word.substring(2);
                    } else {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }
                });
            }

            setSelectedEventType(params.stats.toLowerCase());
            setSelectedType(capitalizeFirstLetter(params.type).replace(/-/g, '_'));

        } else {
            setSelectedEventType("goal-stats");
            setSelectedType('Over_2_5_Goals');
        }
    }, []);

    useEffect(() => {
        if (!params.stats && !params.type) {
            if (lang === 'en') {
                setTypeName('Goal');
                setStatsName('Over 2.5');
            } else {
                setTypeName('Gol');
                setStatsName('2.5 Üst');
            }
        } else {
            const setTypeAndStatsFunc = eventTypes.map((event) => {
                if (event.typeName === params.stats) {
                    setTypeName(event.tabs[0][lang].name);
                    return event.tabs[0][lang].titles.map((item) => {
                        const ff = item.type.replace(/_/g, '-').toLowerCase();
                        if (ff === params.type) {
                            setStatsName(item.odd);
                        }
                    })
                }
            });
            console.log('setTypeAndStatsFunc', setTypeAndStatsFunc);
        }
    },[lang, params.stats, params.type]);

    useEffect(() => {
        navigate(`/best-teams/${selectedEventType.replace(/_/g, '-').toLowerCase()}/${selectedType.replace(/_/g, '-').toLowerCase()}`);
    }, [selectedType, selectedEventType]);

    const onClickEventType = (type) => {
        setSelectedEventType(type.typeName);
        setTypeName(type.tabs[0][lang].name);

        const eventType = eventTypes.find((et) => et.typeName === type.typeName);
        setSelectedType(eventType.tabs[0][lang].titles[0].type);
        const ff = eventType.tabs[0][lang].titles[0].type.replace(/_/g, '-').toLowerCase();
        setStatsName(ff);
    }

    const onClickType = (tabType) => {
        setSelectedType(tabType.type);
        setStatsName(tabType.odd);
    }

    const eventTypeIndex = eventTypes.findIndex((et) => et.typeName === selectedEventType)
    const eventType = eventTypes[eventTypeIndex];
    const subTabIndex = eventType?.tabs[0][lang]?.titles.findIndex((st) => st.type === selectedType)

    return (
        <DocumentMeta {...bestTeamsMeta(typeName, statsName)[lang]}>
            <div className="const-width best-teams-section  const-width-content">
                <Tabs
                    className="best-teams-top-tabs-container"
                    value={eventTypeIndex}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#000000"
                        }
                    }}
                >
                    {
                        eventTypes.map((statsType) => {
                            return (
                                <Tab
                                    key={statsType.typeName}
                                    onClick={() => onClickEventType(statsType)}

                                    className="best-teams-top-tabs"
                                    value={statsType.index}
                                    label={statsType.tabs[0][lang].name}
                                />
                            )
                        })
                    }
                </Tabs>
                <div className="best-teams-bottom-tabs-section">
                    <Tabs
                        className="best-teams-bottom-tabs-container"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={subTabIndex}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#000000"
                            }
                        }}
                    >
                        {
                            eventType?.tabs[0][lang]?.titles?.map((tabTitle, index) => {
                                return (<Tab
                                    key={tabTitle.type}
                                    className="best-teams-bottom-tabs"
                                    onClick={() => onClickType(tabTitle)}
                                    value={index}
                                    label={tabTitle.odd}
                                />)
                            })
                        }
                    </Tabs>
                </div>
                <BestTeamsContent key={selectedType} type={selectedType}/>
            </div>
        </DocumentMeta>
    );
}

export default BestTeams;
