import React, {useEffect, useState} from 'react';
import {Alert, Autocomplete, FormControl, FormHelperText, Input, InputLabel, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import './UpdateTeamDetails.css'
import Button from "@mui/material/Button";
import {fetchTeamSearch, postTeamDetail} from "../../api";

function UpdateTeamDetail(props) {

    const [teamName, setTeamName] = useState(null);
    const [teamId, setTeamId] = useState(null);
    const [abbr, setAbbr] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [open, setOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [teamsData, setTeamsData] = useState([]);

    useEffect(() => {
        if (searchText !== '') {
            fetchTeamSearch(searchText).then((data) => {
                setTeamsData(data.data.data)
            })
        }
    }, [searchText])

    const handleChangeAutoComplete = (event, value) => {
        setTeamId(value.id);
        setTeamName(value.name);
        setAbbr(value?.abbr)
        setIsSelected(true);
    }

    const handleChangeName = (event) => {
        setTeamName(event.target.value)
    }
    const handleChangeAbbr = (event) => {
        setAbbr(event.target.value)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const updateTeamDetail = async (e) => {
        const teamData = {
            id: teamId,
            name: teamName,
            abbr: abbr
        }
        await postTeamDetail(teamData).then((data) => {
            if (data.data.data) {
                setIsSuccess(true);
                setOpen(true);
            } else {
                setIsSuccess(false);
            }
        })
    }


    return (
        <div className='update-teams-name-section'>
            <div className='update-teams-name-container'>
                <Autocomplete
                    getOptionLabel={(option) => (option.name ? `${option.id} - ${option.name}` : null)}
                    /*getOptionSelected={(option, value) => option.name === value.name}*/
                    disablePortal
                    id="teams-combo-box"
                    options={teamsData}
                    onChange={handleChangeAutoComplete}
                    disableClearable
                    onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                            console.log('every click value', value)
                            setSearchText(value)
                        }
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => {
                        return (
                            <TextField
                                id={'Teams'}
                                {...params}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={searchText}
                                label={'Teams'}
                            />
                        )
                    }}
                />

                <FormControl sx={{width: '70%'}}>
                    <TextField
                        id={'TeamId'}
                        variant="outlined"
                        margin="normal"
                        label={'TeamId'}
                        value={teamId === null ? '' : teamId}
                        disabled
                    />
                    <TextField
                        id={'TeamName'}
                        variant="outlined"
                        margin="normal"
                        label={'TeamName'}
                        value={teamName === null ? '' : teamName}
                        onChange={handleChangeName}
                    />
                    <TextField
                        id={'Abbr'}
                        variant="outlined"
                        margin="normal"
                        label={!abbr ? 'Abbr' : 'Abbr'}
                        value={!abbr ? '' : abbr}
                        onChange={handleChangeAbbr}
                    />
                </FormControl>

                <div className='update-button-container'>
                    <Button
                        onClick={updateTeamDetail}
                        disabled={!isSelected}
                        sx={{width: '50%'}} variant="contained" color="success">
                        UPDATE
                    </Button>
                </div>

                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    {
                        isSuccess ?
                            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                                Successfully updated.
                            </Alert>
                            :
                            <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                                An error occurred while updating.
                            </Alert>
                    }

                </Snackbar>
            </div>
        </div>
    );
}

export default UpdateTeamDetail;
