import React from 'react';

function LiveScoresTableHeader(props) {
    const statStyleWidth = () => {
        if (props.currentStats === 'avgStatsHeader') {
            return 'stats-header-red'
        } else {
            return 'live-scores-stats-header-other'
        }
    }

    return (
        <td className={statStyleWidth()}>
            <div className='live-scores-table-header-container'>
                <span className="general-11px-font-style">{props.stHeader.text}</span>
            </div>
        </td>
    );
}

export default LiveScoresTableHeader;
