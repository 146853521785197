import React, {useContext, useEffect, useState} from 'react';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import './LeagueDetails.css'
import LeagueStats from "./league-stats/LeagueStats";
import LeagueStandings from "./league-standings/LeagueStandings";
import {
    fetchAllLeagues, fetchAreas,
    fetchDefaultLeague,
    fetchLeagueDetail,
    fetchLeagueStandings,
    fetchPopularLeagues
} from "../../api";
import {useNavigate, useParams} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, NativeSelect, Select} from "@mui/material";
import Results from "./results/Results";
import Fixtures from "./fixtures/Fixtures";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {leagueDetailsMeta, matchDetailsMeta} from "../../SEO/SeoMetaTags";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";

function LeagueDetails(props) {
    const {lang} = useContext(AppContext);

    const [selectedType, setSelectedType] = useState("stats")
    const [leagueStandingsData, setLeagueStandingsData] = useState(null)
    const [defaultData, setDefaultData] = useState(null)
    const [allLeagues, setAllLeagues] = useState(null)
    const [areasData, setAreasData] = useState(null)
    const [popularLeagues, setPopularLeagues] = useState(null);
    const [selectedLeagueID, setSelectedLeagueID] = useState('');
    const [toggle, setToggle] = useState(0)
    const [league, setLeague] = useState(0);
    const [age, setAge] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.seasonId || !params.leagueId) {
            fetchDefaultLeague().then(data => {
                setDefaultData(data.data.data);
                navigate(`/league/${defaultData.area.toLowerCase()}-${defaultData.league.toLowerCase().replace(/\s+/g, '-')}/${defaultData.leagueId}/${defaultData.seasonId}`);
                /*console.log('defaultData', navigate(`/league-details/${defaultData.seasonId}/${defaultData.leagueId}`))*/
            })
        } else {
            const standings = async () => {
                await fetchLeagueStandings(params.seasonId).then(standingsData => {
                    if (standingsData) {
                        const leagueStandings = standingsData.data.data.groups[0].rows;
                        setLeagueStandingsData(leagueStandings);
                    }
                });
            }
            standings().catch(e => console.log(e))

            const leagueDetail = async () => {
                await fetchLeagueDetail(params.leagueId).then(data => {
                    setLeague(data.data.data);
                });
            }
            leagueDetail().catch(e => console.log(e))
        }

        fetchPopularLeagues().then(data => {
            setPopularLeagues(data.data.data);
        })

        fetchAllLeagues().then(data => {
            setAllLeagues(data.data.data);
        })

        fetchAreas().then(data => {
            setAreasData(data.data.data);
        })

    }, [defaultData, params.seasonId, params.leagueId]);


    const onClickTabsType = (tabType) => {
        setSelectedType(tabType.type);
        setToggle(tabType.toggle);
    }
    const leagueTabs = [
        {
            en: [
                {
                    tabsName: 'Stats',
                    type: 'stats',
                    toggle: 0
                },
                {
                    tabsName: 'Standings',
                    type: 'Standings',
                    toggle: 1
                },
                {
                    tabsName: 'Results',
                    type: 'Results',
                    toggle: 2
                },
                {
                    tabsName: 'Fixtures',
                    type: 'Fixtures',
                    toggle: 3
                }
            ],
            tr: [
                {
                    tabsName: 'İSTATİSTİKLER',
                    type: 'stats',
                    toggle: 0
                },
                {
                    tabsName: 'Sıralama',
                    type: 'Standings',
                    toggle: 1
                },
                {
                    tabsName: 'Sonuçlar',
                    type: 'Results',
                    toggle: 2
                },
                {
                    tabsName: 'FİKSTÜR',
                    type: 'Fixtures',
                    toggle: 3
                }
            ]
        }
    ]
    const subTabIndex = leagueTabs[0][lang].findIndex((st) => st.type === selectedType)

    if (!league && defaultData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const handleChange = () => {
        let value = document.getElementById("leagues").value;
        console.log('value', value);
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}`);
    };
    const handleChangeForPopularLeague = () => {
        let value = document.getElementById("popularLeagues").value;
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}`);
    };
    const handleChangeForAreas = () => {
        let value = document.getElementById("areas").value;
        const seasonId = JSON.parse(value).seasonId;
        const leagueId = JSON.parse(value).leagueId;
        const area = JSON.parse(value).area;
        const league = JSON.parse(value).league;
        setSelectedLeagueID(value);
        navigate(`/league/${area.toLowerCase().replace(/\s+/g, '-')}-${league.toLowerCase().replace(/\s+/g, '-')}/${leagueId}/${seasonId}`);
    };

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <DocumentMeta {...leagueDetailsMeta(league)[lang]}>
            <div className={'const-width league-details-general-section'}>

                <div className={'league-details-flag-and-name-section const-width-content'}>
                    <div className={'flag-and-league-name-container'}>
                        <img className="league-details-country-flag"
                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                             onError={(e) => onImageError(e)}
                             alt={league.league}/>
                        <span className={'league-details-league-name'}>{league.league}</span>
                    </div>

                    <div className='leagues-select-section'>
                        <FormControl>
                            {
                                lang === 'tr' ?
                                    <label className='selects-label' htmlFor="">Popüler Ligler</label>
                                    :
                                    <label className='selects-label' htmlFor="">Popular Leagues</label>
                            }
                            <NativeSelect
                                sx={{
                                    width: '180px',
                                    background: '#f3f3f3'
                                }}
                                id="popularLeagues"
                                key={league.leagueId}
                                value={selectedLeagueID}
                                onChange={(e) => handleChangeForPopularLeague(e)}
                            >
                                {
                                    popularLeagues &&
                                    popularLeagues.map(league => {
                                        return (
                                            <option key={league.leagueId}
                                                    value={JSON.stringify(league)}>{league.league}</option>
                                        )
                                    })
                                }
                            </NativeSelect>
                        </FormControl>

                        <div className='league-details-all-leagues-container'>
                            <FormControl>
                                {
                                    lang === 'tr' ?
                                        <label className='selects-label' htmlFor="">Diğer Ligler</label>
                                        :
                                        <label className='selects-label' htmlFor="">Other leagues</label>
                                }
                                <NativeSelect
                                    sx={{
                                        width: '180px',
                                        background: '#f3f3f3'
                                    }}
                                    id="leagues"
                                    key={league.leagueId}
                                    value={selectedLeagueID}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {
                                        allLeagues &&
                                        allLeagues.map(league => {
                                            return (
                                                <option key={league.leagueId}
                                                        value={JSON.stringify(league)}>{league.league}</option>
                                            )
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                            {/*<label htmlFor="asdasdsad">ALL leagues</label>
                        <select id="mySelect" value={selectedLeagueID} onChange={(e) => handleChange(e)}>
                            {
                                allLeagues &&
                                allLeagues.map(league => {
                                    return (
                                        <option key={league.leagueId} value={JSON.stringify(league)}>{league.league}</option>
                                    )
                                })
                            }
                        </select>*/}
                        </div>

                        <div className='league-details-all-leagues-container'>
                            <FormControl>
                                {
                                    lang === 'tr' ?
                                        <label className='selects-label' htmlFor="">Bölgeler</label>
                                        :
                                        <label className='selects-label' htmlFor="">Areas</label>
                                }
                                {/*<InputLabel id="demo-simple-select-label">All Leagues</InputLabel>*/}
                                <NativeSelect
                                    sx={{
                                        width: '180px',
                                        background: '#f3f3f3'
                                    }}
                                    id="areas"
                                    key={league.leagueId}
                                    value={selectedLeagueID}
                                    onChange={(e) => handleChangeForAreas(e)}
                                >
                                    {
                                        areasData &&
                                        areasData.map(area => {
                                            return (
                                                <optgroup key={area.id} label={area.name}>
                                                    {
                                                        area.leagues.map(league => {
                                                            return (
                                                                <option key={league.leagueId}
                                                                        value={JSON.stringify(league)}>{league.league}</option>
                                                            )
                                                        })
                                                    }
                                                </optgroup>
                                            )
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                </div>

                <div className='league-details-top-tabs-section'>
                    <Tabs
                        value={subTabIndex}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#00b1ff"
                            }
                        }}

                    >
                        {
                            leagueTabs[0][lang].map((tabTitle, index) => {
                                return (<Tab
                                    key={tabTitle.type}
                                    className={'league-details-tab-item'}
                                    onClick={() => onClickTabsType(tabTitle)}
                                    value={index}
                                    label={tabTitle.tabsName}
                                />)
                            })
                        }
                    </Tabs>
                </div>

                {
                    toggle === 0 && <LeagueStats league={league}/>
                }

                {
                    toggle === 1 && <LeagueStandings leagueStandingsData={leagueStandingsData}/>
                }

                {
                    toggle === 2 && <Results/>
                }

                {
                    toggle === 3 && <Fixtures/>
                }
            </div>
        </DocumentMeta>
    );
}

export default LeagueDetails;
