import React, {useContext, useEffect, useState} from 'react';
import BetModeMatches from "./BetModeMatches";
import {AppContext} from "../../context/AppContext";
import {Link} from "react-router-dom";
import LiveScoresTableHeader from "../live-scores/LiveScoresTableHeader";
import {fetchLiteMatches, fetchLiveScoresInstant} from "../../api";

function BetModeByLeagueContent(props) {

    const {favBetModeMatches} = useContext(AppContext);
    const {fetchFavBetModeMatches, liveScoresData} = props;

    const [statsHeader, setStatsHeader] = useState({
        liveScoresTableHeader: [
            {name: 'Corner', text: 'Corner'},
            {name: 'Shots', text: 'Shots'},
            {name: 'S.Target', text: 'S.Target'},
            {name: 'Attack', text: 'Attack'},
            {name: 'Dan.Attack', text: 'Dan.Attack'},
            {name: 'Poss.', text: 'Poss.'},
        ]
    })

    const statStyleWidth = () => {
        return 'bet-mode-stats-header'
    }

    return (
        <div>
            {

                favBetModeMatches &&
                favBetModeMatches.length > 0 &&
                <div>
                    <table className="fixtures-table-section stats-table-header">
                        <tbody>
                        <tr className="league-name-container">
                            {
                                <td className="league-name-and-flag-container">
                                    <span className="area-info-container table-11px-area-header">Favorites</span>
                                </td>
                            }
                            {
                                statsHeader['liveScoresTableHeader'].map((stHeader) => {
                                    return (
                                        <td key={stHeader.name} className={statStyleWidth()}>
                                            <div className='live-scores-table-header-container'>
                                                <span className="general-11px-font-style">{stHeader.text}</span>
                                            </div>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        </tbody>
                    </table>
                    <BetModeMatches league={{matches: fetchFavBetModeMatches}}/>
                </div>
            }
            {
                liveScoresData && liveScoresData.map((league) => {
                    return (
                        <div key={league.leagueId}>
                            <table className="fixtures-table-section stats-table-header">
                                <tbody>
                                <tr className="league-name-container">
                                    {
                                        <td className="league-name-and-flag-container">
                                            {/*<img className="country-flag"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/leagues/${league.areaId}.png`}
                                                 alt="country"/>*/}
                                            <span
                                                className="bet-mode-area-info-container">{league.league}</span>
                                        </td>
                                    }
                                    {
                                        statsHeader['liveScoresTableHeader'].map((stHeader) => {
                                            return (
                                                <td key={stHeader.name} className={statStyleWidth()}>
                                                    <div className='live-scores-table-header-container'>
                                                        <span className="general-11px-font-style">{stHeader.text}</span>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                                </tbody>
                            </table>
                            <BetModeMatches league={league}/>

                        </div>
                    )
                })
            }
        </div>
    );
}

export default BetModeByLeagueContent;
